@import './sass/styles.scss';
@import './components/ThemeSwitch/ThemeSwitch.scss';

body {
    font-family: $mainFont;
    background-color: $mainColor;
    height: 100vh;
   
}

.container {
    width: 90%;
    margin: auto;
}