@import "../../sass/styles.scss";
.return_container {
  width: fit-content;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  margin-top: 20px;
  @media #{$mobile} {
    width: 75%;
    text-align: center;
  }
}
.content_error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  .message {
    color: #721c24;
  }
}
.content_success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  .message {
    color: #155724;
  }
}
.message {
  font-size: 14px;
  text-transform: capitalize;
  @media #{$mobile} {
    font-size: 12px;
  }
}
