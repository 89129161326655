@import "../../sass/styles.scss";

.content {
  display: flex;
  justify-content: space-between;

  @media #{$mobTab},
  #{$smallDesktop} {
    display: block;
  }
}

.dataBlock {
  width: 100%;
  margin: auto;

  @media #{$mobTab},
  #{$smallDesktop} {
    width: 100%;
  }
}

.pageTitle {
  letter-spacing: 0.7em;
  color: $white;
  font-family: $secondaryFont;
  font-size: 40px;
  display: block;
  margin-top: 20px;

  @media #{$mobile} {
    font-size: 25px;
    margin: 0;
  }

  @media #{$tablet} {
    font-size: 30px;
  }

  @media #{$smallDesktop} {
    font-size: 40px;
    margin: 25px 0;
  }
}


.title_wrapper {
  margin: 40px 0;

  @media #{$mobile} {
    margin-top: 5px;
    margin-bottom: 50px;
  }

  @media #{$tablet} {
    margin-top: 5px;
  }

  @media #{$smallDesktop} {
    margin-top: 5px;
  }
}

.countriesTitle {
  font-family: $secondaryFont;
  font-size: 35px;
  letter-spacing: 0.5em;
  color: $white;
  position: relative;

  @media #{$mobile} {
    font-size: 20px;
  }

  @media #{$tablet} {
    font-size: 30px;
  }

  &:before {
    content: "";
    width: 100%;
    background-color: #00a1d7;
    border: 1px solid #00a1d7;
    position: absolute;
    bottom: -20px;
  }
}

.countryWrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-top: 45px;

  &:first-of-type {
    margin-top: 0;
  }

  @media #{$mobile} {
    margin-top: 30px;
  }

  @media #{$minimobile} {
    margin-top: 25px;
  }

}

.countryImage,
.countryName,
.livePhoto {
  display: block;
}

.countryImage,
.livePhoto {
  @media #{$mobile} {
    height: 30px;
  }

  @media #{$smallDesktop} {
    height: 50px;
  }

  @media #{$tablet} {
    height: 40px;
  }
}

.countryName {
  display: block;
  text-transform: uppercase;
  font-size: 30px;
  width: 250px;
  margin-left: 20px;
  text-align: left;
  font-weight: 700;
  color: $white;

  @media #{$mobile} {
    font-size: 16px;
    width: 200px;
  }

  @media #{$tablet} {
    font-size: 20px;
  }

  @media #{$minimobile} {
    width: 140px;
  }

  @media #{$smallDesktop} {
    width: 350px;
  }
}



.team_image,
.team_image_mobile {
  height: 300px;
  animation: zoom-in 1s ease;
  border: 6px solid #116C93;
  border-radius: 50px;

  @media #{$mobTab},
  #{$smallDesktop} {
    margin-top: 20px;
  }

  @media #{$mobile} {
    height: 140px;
  }

  @media #{$ipad} {
    height: 250px;
  }
}

.imageBlock {
  @media #{$mobTab} {
    width: fit-content;
    margin: auto;

  }
}

.subscriptionBlock {
  color: $white;

  &>h3 {
    text-transform: uppercase;
  }

  &>p {
    @media #{$mobile} {
      font-size: 12px;
    }
  }
  @media #{$mobile} {
    padding-bottom: 100px;
  }
}

.team_image_mobile {
  display: none;
  margin: auto;
  height: 250px;

  @media #{$mobTab},
  #{$smallDesktop} {
    display: block;
  }

  @media #{$tablet} {
    height: 450px;
  }

  @media #{$smallDesktop} {
    height: 400px;
  }

  @media #{$mobile} {
    height: 150px;
  }
}

.screen_fadeup .arrow_down {
  display: block;
}

.screen_fadeup {
  position: fixed;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: $white;
  text-transform: uppercase;
  cursor: pointer;
  margin: 20px auto;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
}

.arrow_down {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;

  &:before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    transform: rotate(135deg);
    vertical-align: top;
    width: 20px;
  }
}

.page1 {
  background-image: url("../../assets/photos/background-photo.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  height: 100vh;

  @media #{$mobile} {
    background-size: cover;
    height: 115vh;
  }
  @media #{$ipad} {
    background-size: cover;
  }

}

.page1,
.page2 {
  background-color: $mainColor;
  position: relative;

  @media #{$smallmobile} {
    min-height: 700px;
  }
}

.screen_fadeup_hide {
  display: none;
}

.showPage2 {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: visible;
  transition: all ease-in 0.5s;
  z-index: 1000;
}

.hidePage2 {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  bottom: -100px;
  transition: all ease-out 0.5s;
  z-index: -1;
}

.hidepage1 {
  height: 0;
  overflow: hidden;
  transition: all ease-in 0.5s;
  z-index: -1;
}

.backcontainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  position: relative;
  cursor: pointer;
  animation: pulse 2s infinite;
  --webkit-animation: pulse 2s infinite;
}

.arrow_up {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  border-color: $mainColor;
  content: '';
  display: inline-block;
  height: 10px;
  position: absolute;
  width: 10px;
  left: 50%;
  right: 50%;
  top: 50%;
  text-align: center;
  transform: rotate(-45deg) translate(-30%, -50%);
}

.inputGroup {
  display: flex;
}

.subscribe_button {
  margin-left: 10px;
  background-color: $white;
  font-size: 24px;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: block;
  margin-left: auto;
  font-family: $secondaryFont;
  color: $mainColor;
  border: none;
  cursor: pointer;

  @media #{$mobile} {
    font-size: 16px;
    padding: 7px;
  }
}

.inputLabel {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 30px 0 10px 0;
  color: $white;
  text-transform: capitalize;

  @media #{$mobile} {
    font-size: 12px;
    margin: 20px 0 10px 0;
  }
}

.inputField {
  width: 70%;
  border: none;
  outline: none;
  border-bottom: 4px solid $white;
  padding: 0 20px 5px;
  font-size: 16px;
  background-color: transparent;
  box-sizing: border-box;
  color: $white;
  border-radius: 0;

  @media #{$mobile} {
    font-size: 12px;
    width: 60%;
    border-bottom: 2px solid $white;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(0.5, 0, 5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes zoom-in {
  0% {
    -webkit-transform: scale(0, 0);
  }

  50% {
    -webkit-transform: scale(0.5, 0, 5);
  }

  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    -webkit-opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}