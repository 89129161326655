@import '../../sass/styles.scss';
.header_container {
    width: 100%;
    height: 90px;
    line-height: 90px;
    top: 0;
    z-index: 1000000;
    background-color: $mainColor;
    position: relative;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}

.logo {
display: block;
height: 50px;
@media #{$mobile} {
    height: 20px;
}
}

