.react-switch-checkbox {
    height: 0;
    width: 0;
    display: none;
    &:checked {
        + {
            .react-switch-label {
                .react-switch-button {
                    left: calc(100% - 2px);
                    transform: translateX(-100%);
                }
            }
        }
    }
}
.react-switch-label {
    background-color: $white;
}
.french {
    .react-switch-button {
        background-color: #3EA1D9;
    }
}
.english {
    .react-switch-button {
        background-color: #3EA1D9;
    }
}
.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 45px;
    height: 25px;

    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    .react-switch-button {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 21px;
        height: 21px;
        border-radius: 45px;
        transition: width 0.1s;
        background-color: $mainColor;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center;
    }
    &:active {
        .react-switch-button {
            width: 41px;
        }
    }
}
.theme-switch {
    > label {
        margin: 0 5px;
        color: $white;
        font-size: 13px;
        text-transform: capitalize;
    }
    display: flex;
    align-items: center;
}

// .bg-light {
//     .theme-switch {
//         label {
//             color: $mainColor;
//         }
//     }
// }
