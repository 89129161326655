@import "../../sass/styles.scss";
.loader_container,.loadingphase {
    margin: 10px auto 0 auto;
    border: 5px solid #f3f3f3;
    border-top: 5px solid $mainColor;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.loadingphase {
    position: relative;
    margin: auto;
    top: 25%;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid grey;
    z-index: 2;
}