@import '../../sass/styles.scss';

.footer_container {
position: relative;
padding-bottom: 20px;
}
.logos_container {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    width: 400px;
    @media #{$mobile} {
        width: 300px;
    }
}

.logo_image {
    height: 100px;
    margin: 0 15px;
    object-fit: contain;
    @media #{$mobile} {
        width: 70px;
    }
}

.socialMedia_container {
    margin: 20px auto;
    width: fit-content;
}

// .socialMedia_icon {
//     height: 40px;
//     margin: 0 15px;
//     @media #{$mobile} {
//         height: 30px;
//     }
// }

.icon {
    font-size: 30px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    margin-left: 40px;
    @media #{$mobile} {
      font-size:20px;
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
    }
    &:first-child{
        margin-left: 0;
      }
  }

.copyright {
    display: block;
    text-align: center;
    text-transform: capitalize;
    color: $white;
    font-size: 12px;
    font-weight: 500;
}