@import '../../sass/styles.scss';

.pageTitle {
    display: block;
    font-size: 30px;
    letter-spacing: 0.7em;
    color: $white;
    font-family: $secondaryFont;
    margin-top: 20px;
    @media #{$mobile} {
        font-size: 20px;
        margin: 0;
    }
}

.pageSubtitle {
    display: block;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: $white;
    text-transform: capitalize;
    @media #{$mobile} {
        font-size: 14px;
    }
}


.inputLabel {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 30px 0 10px 0;
    color: $white;
    text-transform: capitalize;
    @media #{$mobile} {
        font-size: 12px;
        margin: 20px 0 10px 0;
    }
}

.inputField {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 4px solid $white;
    padding: 0 20px 5px;
    font-size: 16px;
    background-color: transparent;
    box-sizing: border-box;
    color: $white;
    border-radius: 0;
    @media #{$mobile} {
        font-size: 12px;
        border-bottom: 2px solid $white;
    }
}

.sendButton {
background-color: $white;
font-size: 24px;
padding: 10px;
text-transform: uppercase;
letter-spacing: 0.2em;
display: block;
margin-left: auto;
margin-top: 30px;
font-family: $secondaryFont;
color: $mainColor;
border: none;
cursor: pointer;
@media #{$mobile} {
    font-size: 16px;
    margin: 20px auto 0;
    padding: 10px;
}
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}